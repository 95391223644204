/**
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "typeface-roboto";
import "typeface-roboto-condensed";
import "@vtmn/css/dist/index.css";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
reportWebVitals();
 */



import React from "react";
import { createRoot } from 'react-dom/client';
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "typeface-roboto";
import "typeface-roboto-condensed";
import "@vtmn/css/dist/index.css";

const container = document.getElementById('root');
const myContainer = createRoot(container);
myContainer.render(<App/>);
/**
//window.addEventListener("DOMContentLoaded", function (e) {
  myContainer.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
*/
//})
reportWebVitals();
