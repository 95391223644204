import cryptoRandomString from "crypto-random-string";
import base64url from "base64url";
import { urlback } from "./urlback";
import CryptoES from 'crypto-es';


function auth(setConfig, setUser, setRoles) {
  if (!sessionStorage.getItem("code")) {
    let codeVerifier = sessionStorage.getItem("codeVerifier");
    setUser(null);
    if (!sessionStorage.getItem("Intial URL")) {
      sessionStorage.setItem("Intial URL", window.location);
    }

    if (!window.location.search) {
      codeVerifier = cryptoRandomString({
        length: 43,
        type: "alphanumeric",
      });
      sessionStorage.setItem("codeVerifier", codeVerifier);

      const hashedCodeVerifier = CryptoES.SHA256(codeVerifier).toString(CryptoES.enc.Base64);
      const codeChallenge = base64url.fromBase64(hashedCodeVerifier);
      window.location.href = urlback() + "/login/" + codeChallenge;
      return;
    } else {
      const code = window.location.search.substring(6).split("&")[0];
      sessionStorage.setItem("code", code);
      window.location.href = window.location.origin;
    }
  } else {
    const code2 = sessionStorage.getItem("code");
    const codeVerifier2 = sessionStorage.getItem("codeVerifier");
    sessionStorage.removeItem("code");
    sessionStorage.removeItem("codeVerifier");
    const urlFed2 = urlback() + "/fed2/";
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        code: code2,
        codeVerifier: codeVerifier2,
      }),
    };
    fetch(urlFed2, options)
      .then((res) => res.json())
      .then((res) => {
        setConfig({
          KEY: res.key,
          DRP: res.drp === "true" ? true : false,
          MSG: res.msg,
        });
        setUser({
          profile: { displayName: res.displayName, photourl: res.photourl },
        });
        setRoles(res.roles);
      })
      .catch((err) => console.log("FED2 response KO :", err));
  }
}
export default auth;
