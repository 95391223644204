import React, { useState, useContext, useEffect } from "react";
import logoDK from "../../assets/images/Logos_Web/Logo_Decathlon_RVB.svg";
import { UserContext } from "../../App";
import Spinner from "../../components/Spinner";
import { VtmnButton, VtmnIcon } from "@vtmn/react";
import "@vtmn/icons/dist/vitamix/font/vitamix.css";
import "./homepage.scss";

function Homepage() {
  const { config, user, roles, disconnectUser } = useContext(UserContext);
  const [connected, setConnected] = useState(true);

  useEffect(() => {
    console.log(
      "Start Homepage with config",
      config.DRP ? "DRP in progress" : "Std",
      "with A. key : ...",
      config.KEY
    );
  }, [config]);

  function handleClickLogin(e) {
    if (connected) {
      disconnectUser();
      setConnected(false);
    } else {
      window.location = window.origin;
    }
  }
  const listRole =
    Array.isArray(roles) &&
    roles.map((role, index) => {
      return (
        <tr >
          <td className="tdList" key={index.toString()} >
            <a href={role.url} rel="noreferrer">
              <div>{role.label}</div>
            </a>
          </td>
        </tr>
      );
    });

  return (
    <div className="vtmn-theme_core--light">
      <div className="homepage-menu">
        <div>
          {/* <Icon size={24} value="menu" color="white" /> */}
          <img src={logoDK} className="decathlon-logo" alt="blabla" />
        </div>
        <p className="vtmn-typo_title-4">My AppStream</p>
        <div className="buttonDoc">
          <a
            href="https://docs.google.com/presentation/d/1cXaPcUpMbPvj-_gQEZz3lMeRpnnNRZlAp6uL2OSnbO8/present?slide=id.g75900ac722_2_0"
            target="_blank"
            rel="noreferrer"
          >
            <VtmnButton
              iconAlone={null}
              iconLeft={null}
              iconRight="external-link-line"
              size="medium"
              variant="ghost-reversed"
            >
              Help
            </VtmnButton>
          </a>
        </div>
        <div className="buttonDoc">
          <a
            href="https://sites.google.com/decathlon.com/tes-donnees-personnelles/tu-es-collaborateur?authuser=0"
            target="_blank"
            rel="noreferrer"
          >
            <VtmnButton
              iconAlone={null}
              iconLeft={null}
              iconRight="external-link-line"
              size="medium"
              variant="ghost-reversed"
            >
              Personal Datas
            </VtmnButton>
          </a>
        </div>
        <div className="user-block">
          <p className="vtmn-typo_text-1">
            {user && user.profile?.displayName}
          </p>
          {user && user.profile?.photourl ? (
              <div>
                <img
                  className="picture"
                  alt=""
                  src={user.profile.photourl}
                  onClick={() => handleClickLogin()}
                />
              </div>
          ) : (
            <VtmnIcon
              className="account"
              size={24}
              value="user-fill"
              variant="reversed"
              onClick={() => handleClickLogin()}
            />
          )}
        </div>
      </div>
      {config.MSG && <div className="maintenance">{config.MSG}</div>}
      {user
        ? [
            <div className="homepage-input-container">
              <header>
                <p className="vtmn-typo_title-3">Choose your environment</p>
              </header>
              {roles && roles.length === 0 ? (
                <Spinner />
              ) : (
                <div className="homepage-array vtmn-typo_text-2">
                  <div>
                    <table summary="List Roles">
                      <tbody>{listRole}</tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>,
          ]
        : [
            <div className="connectionMsg">
              <p className="vtmn-typo_title-4">
                {connected
                  ? "Loading..."
                  : "You're disconnected ! Close the window or reconnect"}
              </p>
            </div>,
          ]}
    </div>
  );
}

export default Homepage;
