import React, { useEffect, useState, createContext } from "react";
import "./App.css";
import "typeface-roboto";
import "typeface-roboto-condensed";
import Homepage from "./PrintersManage/homepage";
import Spinner from "./components/Spinner";
import auth from "./components/functions/auth";
export const UserContext = createContext({});

const App = () => {
  const [config, setConfig] = useState(undefined);
  const [user, setUser] = useState(undefined);
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    auth(setConfig, setUser, setRoles);
  }, []);

  useEffect(() => {
    if (roles.length === 1) {
      window.location = roles[0].url;
    }
  });

  const disconnectUser = () => {
    setUser(null);
    sessionStorage.clear();
    localStorage.removeItem("Intial URL");
    sessionStorage.removeItem("codeVerifier");
  };

  if (!config) {
    return (
      <>
        <Spinner />
      </>
    );
  } else {
    return (
      <>
        {roles.length >= 2 ? (
          <UserContext.Provider value={{ config, user, roles, disconnectUser }}>
            <Homepage />
          </UserContext.Provider>
        ) : (
          <Spinner />
        )}
      </>
    );
  }
};

export default App;
