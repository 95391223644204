import React from "react";
import "./Spinner.css";

const Spinner = () => (
  <svg className="styled-spinner" viewBox="0 0 50 50">
    <circle
      className="path"
      cx="25"
      cy="25"
      r="20"
      fill="none"
      strokeWidth="4"
    />
  </svg>
);

export default Spinner;
