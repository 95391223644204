function urlback() {
  const locationOrigin = sessionStorage.getItem("Intial URL");
  if (locationOrigin === "https://myappstream.decathlon.net/") {
    return "https://myappstream.decathlon.net";
  } else if (locationOrigin === "https://appstream.dktapp.cloud/") {
    return "https://appstream.dktapp.cloud";
  } else if (locationOrigin === "https://appstream.pp.dktapp.cloud/") {
    return "https://appstream.pp.dktapp.cloud";
  } else if (locationOrigin === "http://localhost:3000/") {
    return "http://localhost:8888";
  } else {
    return "";
  }
}

module.exports = { urlback };
